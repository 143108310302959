import { defineStore } from 'pinia'
import { axiosInstanceAuth } from '~/client_api/exios'
import { type BasicStatistics, Configuration, StatisticsApi } from '~/client_api'

export const useStatisticsStore = defineStore('statistics', {
  state: (): BasicStatistics => ({
    number_of_reserved_orders: 0,
    sold_items_last_month: 0,
    sold_per_day: [],
  }),

  actions: {
    async getStatistics(branchOfficeId: number) {
      const response = await new StatisticsApi(
        new Configuration(),
        '',
        axiosInstanceAuth
      ).getBasicStatistics(branchOfficeId)
      this.number_of_reserved_orders = response.data.number_of_reserved_orders
      this.sold_items_last_month = response.data.sold_items_last_month
      this.sold_per_day = response.data.sold_per_day
    },
  },
})
